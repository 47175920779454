import Vue from "vue";
import VueRouter from "vue-router";

import QuizEvent from "../views/QuizEvent";
import Quiz from "../views/Quiz";

function alreadyLoggedIn(to, from, next) {
  let user = localStorage.getItem("user");
  if (!user) {
    next("/auth");
  } else {
    next();
  }
}

function acegateAlreadyLoggedIn(to, from, next) {
  let user = localStorage.getItem("user");
  if (!user) {
    next("/auth?redirectFrom=acegate");
  } else {
    next();
  }
}

function isAdmin(to, from, next) {
  let user = JSON.parse(localStorage.getItem("user"));
  if (user && user.role.name == "Website-Admin") {
    next();
  } else {
    next("/noentry");
  }
}

Vue.use(VueRouter);

export const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/pages/:name",
    name: "page",
    component: () => import("../views/Page.vue"),
  },
  {
    path: "/auth",
    name: "Auth",
    component: () => import("../views/Auth.vue"),
  },
  {
    path: "/auth/:state",
    name: "Auth",
    component: () => import("../views/Auth.vue"),
  },
  {
    path: "/events",
    name: "Events",
    component: () => import("../views/Events.vue"),
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: () => import("../views/Privacy.vue"),
  },
  {
    path: "/events/:name",
    name: "Event",
    component: () => import("../views/Event.vue"),
  },
  {
    path: "/event/:name",
    name: "Event",
    component: () => import("../views/Event.vue"),
  },
  {
    path: "/ideace",
    name: "Events",
    component: () => import("../views/Events.vue"),
  },
  {
    path: "/ideace/:name",
    name: "Event",
    component: () => import("../views/Event.vue"),
  },
  {
    path: "/arcause/:name",
    name: "Event",
    component: () => import("../views/Event.vue"),
  },
  {
    path: "/ideace/events/:name",
    name: "Event",
    component: () => import("../views/Event.vue"),
  },
  {
    path: "/acegate/registration/:id",
    name: "AcegateRegistration",
    component: () => import("../views/AcegateRegistration.vue"),
  },
  {
    path: "/ideace/quizzes/:id",
    name: "Quiz",
    component: QuizEvent,
  },
  {
    path: "/arcause",
    name: "Arcause",
    component: () => import("../views/Arcause.vue"),
  },
  {
    path: "/quiz/:id",
    name: "Quiz",
    beforeEnter: alreadyLoggedIn,
    component: Quiz,
  },
  {
    path: "/quiz/acegate/:acegateId",
    name: "Quiz",
    beforeEnter: alreadyLoggedIn,
    component: () => import("../views/Quiz.vue"),
  },
  {
    path: "/question/:id",
    name: "Quiz",
    beforeEnter: alreadyLoggedIn,
    component: () => import("../components/quiz-question.vue"),
  },
  {
    path: "/quiz/:id/rankings",
    name: "Quiz",
    beforeEnter: alreadyLoggedIn && isAdmin,
    component: () => import("../views/QuizRanking.vue"),
  },
  {
    path: "/event/:id/registrations",
    name: "Event",
    beforeEnter: alreadyLoggedIn && isAdmin,
    component: () => import("../views/Registrations.vue"),
  },
  {
    path: "/ideace/:id/registrations",
    name: "Event",
    beforeEnter: alreadyLoggedIn && isAdmin,
    component: () => import("../views/Registrations.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    beforeEnter: alreadyLoggedIn,
    component: () => import("../views/Profile.vue"),
  },
  {
    path: "/massmailer",
    name: "Mass Mailer",
    beforeEnter: alreadyLoggedIn && isAdmin,
    component: () => import("../views/Massmailer.vue"),
  },
  {
    path: "/jury/:id",
    name: "Jury",
    beforeEnter: alreadyLoggedIn,
    component: () => import("../views/Jury.vue"),
  },
  {
    path: "/juryresults/:id",
    name: "JuryResults",
    beforeEnter: alreadyLoggedIn && isAdmin,
    component: () => import("../views/JuryResults.vue"),
  },
  {
    path: "/inactiveUsers",
    name: "Inactive Users",
    beforeEnter: alreadyLoggedIn && isAdmin,
    component: () => import("../views/MassMail.vue"),
  },
  {
    path: "/qcategories",
    name: "questionCategories",
    beforeEnter: alreadyLoggedIn && isAdmin,
    component: () => import("../views/QuestionCategories.vue"),
  },
  {
    path: "/bulkresultupload",
    name: "BulkResultUpload",
    beforeEnter: alreadyLoggedIn && isAdmin,
    component: () => import("../views/BulkResultUpload.vue"),
  },
  {
    path: "/acedge",
    name: "Acedge",
    component: () => import("../views/Acedge.vue"),
  },
  {
    path: "/industreal",
    name: "Industreal",
    component: () => import("../views/Industreal.vue"),
  },
  {
    path: "/connectaid",
    name: "Connectaid",
    component: () => import("../views/Connectaid.vue"),
  },
  {
    path: "/acegate",
    name: "Acegate",
    beforeEnter: acegateAlreadyLoggedIn,
    component: () => import("../views/Acegate.vue"),
  },
  {
    path: "/questions",
    name: "Questions",
    beforeEnter: alreadyLoggedIn && isAdmin,
    component: () => import("../views/Questions.vue"),
  },
  {
    path: "/unsubscribe",
    name: "Unsubscribe",
    beforeEnter: alreadyLoggedIn,
    component: () => import("../views/Unsubscribe"),
  },
  {
    path: "/dashboard",
    name: "admin",
    beforeEnter: alreadyLoggedIn && isAdmin,
    component: () => import("../views/Admin.vue"),
  },
  {
    path: "/noentry",
    name: "noentry",
    component: () => import("../views/NoEntry"),
  },
  {
    path: "/gallery",
    name: "gallery",
    component: () => import("../views/Gallery"),
  },
  {
    path: "/partners",
    name: "partners",
    component: () => import("../components/partners"),
  },
  {
    path: "/file/:id",
    name: "File",
    component: () => import("../views/File"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.fullPath.substr(0, 2) === "/#") {
    const path = to.fullPath.substr(2);
    next(path);
    return;
  }
  next();
});

export default router;
