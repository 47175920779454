<template>
  <div class="qcontainer">
    <div>
      <slot />
    </div>
    <h2 class="mt20 question flex">Q: 
      <span 
        style="font-weight: 400; font-size: 20px !important; margin-left: 12px;" 
        class="myauto" 
        v-html="getHtml(q.Question)"></span>
    </h2>
    <div v-if="q.Image" class="image" :style="imageStyles">
    </div>
    <div class="table" v-if="q.html" v-html="q.html">
    </div>
    <div class="pl40 vertical" style="margin-top: 20px;"  v-if="q.type == 'numerical'">
      <input v-model="answer" class="py12 px8 w200" type="number" placeholder="Enter Answer" />
      <button class="ml12 submit_button br2 mt12 brown500" @click="updateText">Submit</button>
    </div>
    <div class="answers vertical" v-if="q.type == 'openEnded'">
      <textarea placeholder="Enter Response" v-model="answer" cols="30" rows="5"></textarea>
      <!-- button class="submit_button mt12 brown500" @click="updateText">Submit</button -->
    </div>
    <div v-if="!q.type || q.type == 'multipleChoice'" class="answers">
      <div class="ans" v-for="(ans, i) in q.Answers" @click="selection(i)"  :key="ans.id">
        <div class="wfill flex"
            :class="{
                selected: selected.includes(alphabets[i])
            }"
            :key="ans.id">
          <div class="num">
            <span>{{alphabets[i]}}</span>
          </div>
          <p class="center grow">{{ans.Answer}}</p>
        </div>
        <div v-if="ans.Image">
          <div class="wfill" style="height: 150px;" :style="{
            background: `url(${ans.Image.url}) center / contain no-repeat, #f5f5f5`
          }"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'quiz-question',
  props: {
    q: {default: {}},
    ans: {default: null}
  },
  watch: {
    answer: function () {
      this.$emit('interacted')
    },
    ans: function (v) {
      this.answer = v
    }
  },
  data () {
    return {
      alphabets: ['A', 'B', 'C', 'D'],
      answer: this.ans
    }
  },
  mounted () {
    if (this.$route.path.includes('/question/')) {
      let id = this.$route.params.id
      this.$req.get(`questions/${id}`, {
        headers: {
          Authorization: 'Bearer ' + this.$bus.token 
        }
      })
        .then(r => {
          this.q = r.data
        })
        .catch(e => console.log(e))
    }
  },
  computed: {
    selected: function () {
      return this.ans
    },
    imageStyles: function () {
      return {
        width: '100%',
        height: '240px',
        backgroundImage: 'url(\'' + this.q.Image.url + '\')',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }
    }
  },
  methods: {
    selection: function (i) {
      if (this.q.CorrectAnswer.indexOf('|') != -1) {
        // Multiple choice / Multiple correct
        let ans = this.ans.split('|')

        if (ans.indexOf(this.alphabets[i]) == -1) {
          ans.push(this.alphabets[i])
        } else {
          ans = ans.filter(a => a !== this.alphabets[i])
        }
        this.$emit('update:ans', ans.join('|'))
      } else {
        this.$emit('update:ans', this.alphabets[i])
      }
      
      // this.$emit('selected')
    },
    updateText: function () {
      this.$emit('update:ans', this.answer)
      this.$emit('selected')
      this.answer = ''
    },
    getHtml: function (questionHtml) {
      if (this.q.CorrectAnswer.indexOf('|') != -1) {
        return questionHtml + '<br><p>(Select all that apply)</p>'
      }
      return questionHtml
    }
  }
}
</script>

<style lang="css" scoped>
.qcontainer {
  position: relative;
  flex-grow: 1;
  padding-bottom: 20px;
  min-height: 600px;
}

.submit_button {
  width: 120px;
  margin-left: auto;
}



/* @media (max-width: 600px) {
  .qcontainer {
    height: 600px;
  }
} */

.question {
  padding: 0 6%; 
  margin-top: 70px;
  font-size: 20px;
  width: 100%;
  box-sizing: border-box;
}
.question span * {
  font-size: 20px !important;
}

h5 {
  text-align: center;
  text-transform: uppercase;
  color: hsla(0,0%,100%,.6);
  font-weight: 400;
  letter-spacing: 1px;
}

.num {
  width: 40px;
  height: 100%;
  display: flex;
  left: 0px;
  top: 0px;
  background: #E9D4B9;
  min-height: 40px;
}

.num span {
  margin: auto;
  color: #fff;
}

.answers {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 80px;
}

.table {
  width: fit-content;
  margin: auto;
  margin-top: 40px;
}

.table + .answers {
  /* position: relative;
  /* top: 200px; */
}

.image {
  /* position: relative;
  top: 172px; */
  margin-top: 30px;
}

.image + .answers {
  /* position: relative;
  top: 200px; */
}

.answers.vertical {
  flex-direction: column;
  padding: 0 20px;
}

.answers textarea {
  padding: 12px;
}

.ans {
  width: calc(50% - 10%);
  border-radius: 4px;
  border: 1px solid #E9D4B9;
  margin: 8px;
  cursor: pointer;
  color: #252525;
  position: relative;
  overflow: hidden;
}

.ans .selected {
  background: #E9D4B9;
}

.ans.selected .num {
  background: #6B3E1E;
}

.ans:hover {
  background: #FDF7F0;
}

.ans:active{
  background: #F9F2EA;
}

@media (max-width: 1000px) {
  .ans {
    width: calc(50% - 10%);
  }
}

@media (max-width: 600px) {
  .ans {
    width: calc(100% - 20%);
  }
}

.ans p {
  margin: auto;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 12px;
}
</style>