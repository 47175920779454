<template>
  <div class="event px40" style="padding-top: 20px;">
    <a class="pointer" @click="$router.push('/events')" style="padding-left: calc(10% + 40px);">Back to Events</a>
    <quiz-campaign v-if="active" :campaign="active" :showImages="true"></quiz-campaign>
  </div>
</template>

<script>
  import quizCampaign from '../components/quiz-campaign'
  export default {
    name: 'Event',
    components: {quizCampaign},
    data () {
      return {
        active: ''
      }
    },
    mounted () {
      let id = this.$route.params.id
      this.$req.get(`quizzes?id=${id}`, this.$bus.headers)
      .then(r => {
        this.active = {
          ...r.data[0],
          type: 'livequiz'
        }
      })
      .catch(e => console.log(e))
    }
  }
</script>