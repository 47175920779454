<template>
  <span>
    <p
      v-if="!allLoaded"
      style="position: relative; top: 60px; left: 40px; font-size: 20px;"
      class="bold italic brown500"
    >
      Loading Test ...
    </p>
    <div
      class="hfill"
      :style="{ visibility: allLoaded ? 'visible' : 'hidden' }"
    >
      <div class="end_parent" v-if="answers.length == 0">
        <h2 class="question">Quiz Not Found</h2>
      </div>
      <div v-if="answers.length > 0 && quiz == 1" class="end_parent">
        <!-- Previous entry exists -->
        <h2 class="question">You have already submitted this quiz</h2>
        <button
          @click="
            isAcegate ? $router.push('/acegate') : $router.push('/ideace')
          "
          class="black quizCompleteButton"
        >
          Go back to Quizzes
        </button>
      </div>
      <div v-else class="hfill flex" style="position: relative;">
        <div class="end_parent" v-if="showInstructions">
          <div
            v-if="quiz.Instructions"
            style="width: 114%; margin-left: -7%"
            class="wfill mb40 brown500"
          >
            <h6 style="margin-left: 6%" class="fs18 mt24 mb12">Instructions</h6>
          </div>
          <div
            v-html="quiz.Instructions"
            style="line-height: 20px; margin-top: 40px;"
          ></div>
          <span class="relative" v-if="quiz.showGeneralInstructions">
            These mock tests are supposed to be taken in one go.
            <br /><br />
            The Question Palette displayed on the right-hand side of the screen
            will show the status of each question using one of the following
            symbols
            <br /><br />
            <div>
              <div style="width: 50%;" class="flex">
                <quiz-status-block
                  style="transform: scale(0.8); margin: 5px;"
                  status="answered"
                >
                </quiz-status-block>
                <p class="fs12" style="margin-top: auto; margin-bottom: auto;">
                  Answered
                </p>
              </div>
              <div style="width: 50%;" class="flex">
                <quiz-status-block
                  num="9"
                  style="transform: scale(0.8); margin: 5px;"
                  status="visited"
                >
                </quiz-status-block>
                <p class="fs12" style="margin-top: auto; margin-bottom: auto;">
                  Not Answered
                </p>
              </div>
              <div style="width: 50%;" class="flex">
                <quiz-status-block
                  num="4"
                  style="transform: scale(0.8); margin: 5px;"
                  status="default"
                >
                </quiz-status-block>
                <p class="fs12" style="margin-top: auto; margin-bottom: auto;">
                  Not Visited
                </p>
              </div>
              <div style="width: 50%;" class="flex">
                <quiz-status-block
                  num="6"
                  style="transform: scale(0.8); margin: 5px;"
                  status="review"
                >
                </quiz-status-block>
                <p class="fs12" style="margin-top: auto; margin-bottom: auto;">
                  Marked for Review
                </p>
              </div>
              <div style="width: 100%;" class="flex">
                <quiz-status-block
                  style="transform: scale(0.8); margin: 5px;"
                  status="reviewanswered"
                >
                </quiz-status-block>
                <p class="fs12" style="margin-top: auto; margin-bottom: auto;">
                  Answered & Marked for Review
                </p>
              </div>
              <!-- -->
            </div>
            <div>
              <br /><br />
              The question paper consists of Multiple Choice Questions (MCQ) and
              Numerical Answer Type (NAT).
              <br /><br />
              a) Multiple choice type questions have four choices. Select your
              answer by clicking on the radio button placed before the choice,
              with the help of the mouse.<br />
              <br />
              b) For numerical answer type questions, a numerical answer should
              be entered with the help of the keyboard.
              <br /><br />
              All those questions that are not attempted will carry zero marks.
              However, wrong answers for multiple choice type questions (MCQ)
              will carry NEGATIVE marks. For multiple choice type questions, a
              wrong answering will lead to deduction of ⅓ (0.33)mark for a
              1-mark question and ⅔ (0.66)mark for 2-marks question. There is no
              negative marking for NAT questions.<br />
              <br /><br />
              Kindly note the procedure for answering a multiple choice type
              question (MCQ) :
              <br /><br />
              To select your answer, click on the button of the corresponding
              option.
              <br /><br />
              To deselect your chosen answer, click on the button of the chosen
              option once again or click on the Clear Data button. To change
              your chosen answer, click on the button of the newly identified
              answer.
              <br /><br />
              Procedure for answering a numerical answer type (NAT) question:
              <br /><br />
              To enter a numerical answer use your key board.
              <br /><br />
              To clear your answer, click on the
              <button class="compact red my8 mr8">Clear Response</button>
              button. <br /><br />
              To change your answer to a question that has already been
              answered, first select that question and then follow the usual
              procedure for answering any question.
              <br /><br /><br />
              <strong>Saving your answer</strong>
              <br />
              To save your answer, you MUST click on the
              <button class="compact primary my8 mr8">Save & Next</button
              >button. To mark the question for review, click on the ‘Mark for
              later checking (Review) & Go Next’ button.
              <br />
              After the elapse of time scheduled for the examination (180
              minutes), all the answers (including those “Answered and Marked
              for Review”) please click on ‘Submit’ to submit the paper..
              <br />
              Please ensure that your system is plugged into a power source and
              a stable internet source since the system will save the test only
              when you complete the test
              <br /><br /><br />

              <strong>Navigating through sections</strong> <br /><br />
              Sections in this question paper are displayed above the Question
              Area. Questions in a section can be viewed by clicking on the
              section name. The section you are currently viewing is
              highlighted. After clicking the
              <button class="compact primary my8 mr8">Save & Next</button>
              button on the last question of any section, you will automatically
              be taken to the first question of the next section. You can
              shuffle between sections and questions anytime during the
              examination. You can see the current section’s summary as a part
              of the legend appearing above the Question Palette Charts, graph
              sheets, calculators and mathematical tables will NOT be allowed in
              the examination hall, so try to practice without them. You must
              use the scribble pad for your rough work.
            </div>
          </span>
          <span v-else class="flex" style="height: 100%; flex-grow: 1">
            <!-- <button
              @click="
                showInstructions = false;
                quizInteracted();
              "
              style="width: 160px;"
              class="brown500 wfill mauto"
            >
              Begin Quiz
            </button> -->
          </span>
          <div class="submit_panel">
            <button
              class="mlauto my12 brown500"
              style="margin-right: 6%;"
              @click="
                showInstructions = false;
                quizInteracted();
              "
            >
              Begin Quiz
            </button>
          </div>
        </div>
        <quiz-question
          ref="question"
          v-else-if="ix < answers.length"
          :ans.sync="answers[ix]"
          :q="questions[ix]"
          @interacted="quizInteracted"
          @selected="questionAnswered"
        />
        <div class="end_parent" v-else>
          <h2 class="question">Quiz Complete</h2>
          <p>Please take your time and review your responses</p>
          <button @click="saveNExit" class="black quizCompleteButton">
            Submit & Exit Quiz
          </button>
        </div>
        <div v-if="showQuizElements" class="flex top_panel">
          <calculator class="mlauto" />
          <p class="ml8 timer">
            Time Left <span>{{ elapsedTime }}</span>
          </p>
        </div>
        <div v-if="showQuizElements" class="submit_panel">
          <p v-if="$mq != 'mobile'" class="ml32 timer">
            Time Left <span>{{ elapsedTime }}</span>
          </p>
          <p v-else style="margin: auto; margin-left: 32px">
            Q{{ ix + 1 }} of {{ questions.length }}
          </p>
          <button
            v-if="$mq == 'mobile' && ix != 0"
            class="compact black mlauto my8 mr8"
            @click="goToQuestion(ix - 1)"
          >
            Previous
          </button>
          <button
            v-if="$mq != 'mobile'"
            @click="answers[ix] = ''"
            class="compact red my8 mlauto mr8"
          >
            Clear Response
          </button>
          <button
            v-if="!forReview.includes(ix) && $mq != 'mobile'"
            @click="
              forReview.push(ix);
              questionAnswered();
            "
            class="compact blue my8 mr8"
          >
            Mark for review & Next
          </button>
          <button
            v-if="forReview.includes(ix) && $mq != 'mobile'"
            @click="forReview = forReview.filter(f => f != ix)"
            class="compact blue my8 mr8"
          >
            Unmark Review
          </button>
          <button
            @click="questionAnswered"
            :class="[$mq == 'mobile' && ix == 0 && 'mlauto']"
            class="compact primary my8 mr8"
          >
            {{ $mq == "mobile" ? "Next" : "Save & Next" }}
          </button>
        </div>
        <quiz-status
          v-if="showQuizElements && $mq != 'mobile'"
          ref="status"
          :title="quiz.title"
          :visited.sync="visited"
          :forReview.sync="forReview"
          @blockClicked="goToQuestion(...arguments)"
          @saveNExit="saveNExit"
          :answers="answers"
          class="hfill"
        />
      </div>
    </div>
  </span>
</template>

<script>
import moment from "moment";
import calculator from "../components/calculator";
import quizStatusBlock from "../components/quiz-status-block";
import quizStatus from "../components/quiz-status";
import quizQuestion from "../components/quiz-question";
export default {
  name: "quiz",
  components: { quizStatus, quizQuestion, quizStatusBlock, calculator },
  data() {
    return {
      allLoaded: false,
      showInstructions: true,
      creationInProgress: false,
      quizId: "",
      quizEntryId: "",
      quiz: {},
      questions: [],
      answers: [],
      forReview: [],
      visited: [],
      ix: 0,
      timeLeft: 0,
      timer: null
    };
  },
  computed: {
    elapsedTime: function() {
      return new Date(this.timeLeft * 1000).toISOString().substr(11, 8);
    },
    showQuizElements: function() {
      return !this.showInstructions;
    },
    isAcegate: function() {
      let quizId = this.$route.params.id;
      return quizId && quizId == "acegate";
    }
  },
  created() {
    /* eslint-disable-next-line no-undef */
    $(document).scrollTop(0);
  },
  mounted() {
    this.quizId = this.$route.params.id;

    if (this.quizId && this.quizId != "acegate") {
      // Getting existing quiz entries
      this.$req
        .get(
          `quiz-entries/count?users.email=${this.$bus.user.email}&quiz=${this.quizId}`,
          {
            headers: {
              Authorization: `Bearer ${this.$bus.token}`
            }
          }
        )
        .then(entry => {
          if (entry && entry.data) {
            // Autofilling previous entry
            alert("You have already attempted this quiz");
            this.$router.push("/ideace");
            return;
          }
        })
        .catch(e => console.log(e));
    } else {
      this.quizId = this.$route.params.acegateId;
    }

    this.$req
      .get("quizzes/" + this.quizId, {
        headers: {
          Authorization: `Bearer ${this.$bus.token}`
        }
      })
      .then(r => {
        this.quiz = r.data;
        if (this.answers.length <= 0) {
          // Entry does not exist
          this.generateQuiz(this.quiz.breakdown, () => {
            this.allLoaded = true;
          });
        }
      })
      .catch(e => console.log(e));
  },
  methods: {
    generateQuiz: function(breakdown, cb) {
      this.$req
        .post(
          "generatequiz",
          {
            id: this.quizId
          },
          {
            headers: {
              Authorization: `Bearer ${this.$bus.token}`
            }
          }
        )
        .then(r => {
          this.questions = r.data;
          this.answers = new Array(r.data.length).fill("");
          cb();
          // this.createEntry(cb)
        })
        .catch(e => console.log(e));
    },
    generateAcegateQuiz: function(cb) {
      this.$req
        .post("generateAcegateQuiz", {}, this.$bus.headers)
        .then(r => {
          this.questions = r.data;
          this.answers = new Array(r.data.length).fill("");
          cb();
          // this.createEntry(cb)
        })
        .catch(e => console.log(e));
    },
    goToQuestion: function(i) {
      if (!this.quizEntryId && !this.creationInProgress) {
        this.createEntry(() => {
          console.log("Go to question");
        });
      }
      this.ix = i;
      this.visited.push(this.ix);
    },
    quizInteracted: function() {
      if (!this.quizEntryId && !this.creationInProgress) {
        this.createEntry(() => {
          console.log("Quiz interacted");
        });
      }
      this.visited.push(0);
    },
    questionAnswered: function() {
      if (!this.quizEntryId && !this.creationInProgress) {
        this.createEntry(console.log("Question Answered"));
      }
      this.answers[this.ix] = String(this.$refs.question.answer);
      this.$refs.question.answer = "";
      if (this.ix != this.questions.length) {
        this.ix += 1;
        this.visited.push(this.ix);
      }
      this.$refs.status.setCurrent(this.ix);
    },
    getMarks: function(questions, answers) {
      let marks = 0;
      answers.map((ans, i) => {
        if (questions[i].CorrectAnswer == ans) {
          marks += questions[i].Marks;
        }
      });
      return marks;
    },
    setTimer: function(entry) {
      let deadline = moment(entry.created_at).add(this.quiz.timeLimit, "m");
      let now = moment();
      let diff = deadline.diff(now, "seconds");
      if (diff < 1) {
        // Passed deadline
        this.quiz = 1;
      } else {
        this.timeLeft = diff;
        this.timer = setInterval(() => {
          if (this.timeLeft != 0) {
            this.timeLeft -= 1;
          } else {
            if (this.quizEntryId) {
              this.saveNExit();
            }
            clearInterval(this.timer);
          }
        }, 1000);
      }
    },
    processQuestions: function(questions) {
      return questions.map(q => {
        const ques = { ...q };
        ques["Category"] = {
          id: ques?.Category?.id,
          name: ques?.Category?.name,
          abbrName: ques?.Category?.abbrName
        };
        ques["subcategory"] = {
          id: ques?.subcategory?.id,
          name: ques?.subcategory?.name,
          abbrName: ques?.subcategory?.abbrName
        };
        ques["Image"] = {
          url: ques?.Image?.url
        };
        delete ques["created_by"];
        delete ques["updated_by"];
        delete ques["created_at"];
        delete ques["updated_at"];
        return ques;
      });
    },
    createEntry: function(cb = () => {}) {
      this.creationInProgress = true;
      this.$req
        .post(
          `quiz-entries`,
          {
            entry: {
              answers: this.answers,
              questions: this.processQuestions(this.questions)
            },
            marks: this.getMarks(this.questions, this.answers),
            timeLeft: this.timeLeft,
            quiz: this.quizId,
            users: [this.$bus.user.id]
          },
          {
            headers: {
              Authorization: `Bearer ${this.$bus.token}`
            }
          }
        )
        .then(r => {
          this.quizEntryId = r.data.id;
          this.setTimer(r.data);
          this.creationInProgress = false;
          cb();
        })
        .catch(e => console.log(e));
    },
    saveNExit: function() {
      this.$req
        .put(
          `quiz-entries/${this.quizEntryId}`,
          {
            entry: {
              answers: this.answers,
              questions: this.processQuestions(this.questions)
            },
            marks: this.getMarks(this.questions, this.answers),
            timeLeft: this.timeLeft
          },
          {
            headers: {
              Authorization: `Bearer ${this.$bus.token}`
            }
          }
        )
        .then(() => {
          alert("Quiz Complete!");
          if (this.isAcegate) {
            this.$router.push("/acegate");
          }
          this.$router.push("/ideace");
          clearInterval(this.timer);
        })
        .catch(e => console.log(e));
    }
  }
};
</script>

<style lang="css" scoped>
.end_parent {
  flex-grow: 1;
  padding: 0 6%;
}
.question {
  margin-top: 60px;
  font-size: 32px;
  width: 100%;
  box-sizing: border-box;
}
.question + p {
  margin-top: 12px;
  color: #777;
}
.quizCompleteButton {
  margin-top: 20px;
}
.timer {
  position: relative;
  top: 22px;
  left: 0px;
}
.timer span {
  color: #b98744;
  font-weight: 500;
}
.submit_panel {
  height: 60px;
  width: 100%;
  position: fixed;
  bottom: 0px;
  left: 0px;
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08), 0 3px 6px rgba(0, 0, 0, 0.12);
  z-index: 20;
  display: flex;
}
.top_panel {
  height: 60px;
  width: calc(100% - 300px);
  position: absolute;
  top: 12px;
  display: flex;
  left: 0px;
  padding-right: 80px;
}
@media (max-width: 800px) {
  .top_panel {
    width: auto;
  }
}
</style>
