<template>
  <div id="app">
    <navbar
      v-if="!$route.fullPath.includes('pages')"
      class="navbar"
      @toggle-drawer="showSidebar"
    />
    <sidebar @close="closeSidebar" v-if="!$route.fullPath.includes('pages')" />
    <div style="position: relative; top: 60px;" class="container">
      <router-view />
    </div>
    <site-footer v-if="!$route.fullPath.includes('pages')"></site-footer>
  </div>
</template>

<script>
// import st from 'storerestapi'
import navbar from "./components/navbar";
import sidebar from "./components/sidebar";
import siteFooter from "./components/site-footer";
export default {
  name: "app",
  components: { navbar, sidebar, siteFooter },
  data () {
    return {
      showNavbar: false
    }
  },
  mounted() {
    // Force logs people with expired tokens out
    // this.$baseReq.get("users/me", this.$bus.headers)
    //   .then(r => {
    //     st.setVar('user', r.data)
    //   })
    //   .catch(() => {
    //     st.deleteVar('user')
    //     st.deleteVar('token')
    //     this.$bus.isLoggedIn = false
    //   })
    this.$req
      .get("home", {
        cache: {
          maxAge: 2 * 60 * 1000, // 2 min instead of 15 min
          exclude: { query: false }
        }
      })
      .then(r => {
        this.$bus.siteData = r.data;
      })
      .catch(e => console.log(e));
  },
  methods: {
    showSidebar: function() {
      // eslint-disable-next-line no-undef
      $(".ui.sidebar").sidebar("toggle");
    },
    closeSidebar: function() {
      // eslint-disable-next-line no-undef
      $(".ui.sidebar").sidebar("toggle");
    }
  }
};
</script>

<style lang="scss">
@import "./styles.scss";

* {
  font-family: "Open Sans", Helvetica, sans-serif;
}
.container {
  flex-grow: 1;
  width: 100%;
}
.footer {
  background: #252525;
  margin-top: 90px;
  background-size: cover;
  background-position: center;
  height: 250px;
  width: calc(100%);
  display: flex;
  flex-direction: column;
  position: relative;
  flex-shrink: 0;
}
</style>
