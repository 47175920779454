<template>
  <div class="hero_desc" :class="{ horizontal }" @click="clickHandler">
    <div
      class="icon_circle"
      :class="{ horizontal }"
      :style="{ background: vertical.color }"
    >
      <div
        v-if="vertical.icon.url"
        style="margin: auto;"
        class="vertical_icon"
        :class="{ horizontal }"
        :style="{
          background:
            'url(' + vertical.icon.url + ') center / contain no-repeat',
          height: horizontal ? '12px' : '28px',
          width: horizontal ? '12px' : '28px'
        }"
      ></div>
      <div
        v-else
        :style="{
          height: horizontal ? '12px' : '28px',
          width: horizontal ? '12px' : '28px'
        }"
        style="margin: auto; display: flex;"
      >
        <i
          style="color: white;"
          :class="[horizontal && 'horizontal', vertical.icon + ' icon']"
        ></i>
      </div>
    </div>
    <div v-if="!horizontal" class="mt12">
      <h3 class="hero_section_title">{{ vertical.name }}</h3>
      <p class="hero_section_description">{{ vertical.description }}</p>
      <a @click="$bus.redirect(vertical.link, false, true)">
        <p style="margin-top: 40px;">
          Learn More <i class="right arrow icon"></i>
        </p>
      </a>
    </div>
    <div v-else class="desc ml8">
      <h5 class="hero_section_title" :class="{ horizontal }">
        {{ vertical.name }}
      </h5>
      <p :class="{ horizontal }" class="hero_section_description">
        {{ vertical.description }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "hero-section",
  props: {
    horizontal: { default: false },
    vertical: {
      default: () => {
        return {
          name: "Acedge",
          description: "E-learning for the Aced Fraternity",
          link: "http://acedge.in",
          icon: {
            url:
              "https://ethosstrapibucket.s3.ap-south-1.amazonaws.com/aa_b3a1e9f258.jpg"
          },
          color: "#5ccbaa"
        };
      }
    }
  },
  methods: {
    clickHandler: function() {
      if (this.vertical) {
        this.$bus.redirect(this.vertical.link, false, true);
      }
    }
  }
};
</script>

<style>
.icon_circle {
  display: flex;
  margin-top: 32px;
  width: 64px;
  height: 64px;
  border-radius: 40px;
  background: #5ccbaa33;
  flex-shrink: 0;
}

.icon_circle.horizontal {
  width: 32px;
  height: 32px;
}
.icon_circle i {
  margin: auto;
  font-size: 20px;
  color: rgba(100, 100, 100, 0.3);
}
.icon_circle i.horizontal {
  font-size: 12px;
}
.hero_section_title {
  text-transform: uppercase;
  margin-top: 20px;
  font-weight: 700;
  font-family: "Open Sans", "Helvetica", sans-serif;
  font-size: 20px;
  letter-spacing: 0.8px;
  color: #444;
}
.hero_section_title.horizontal {
  font-size: 14px !important;
}
.hero_section_description {
  margin-top: 20px;
  padding-right: 12px;
  font-size: 16px !important;
  color: #444;
}
.hero_section_description.horizontal {
  margin-top: 4px !important;
}
.hero_desc {
  padding-bottom: 60px;
}

.hero_desc a {
  font-size: 20px;
}

.hero_desc.horizontal {
  display: flex;
  padding: 12px;
}
.hero_desc.horizontal .desc {
  display: flex;
  flex-direction: column;
}
.hero_desc.horizontal .desc * {
  text-align: left;
  margin-left: 16px;
}
.hero_desc.horizontal {
  font-size: 16px !important;
}
.hero_desc.horizontal .hero_section_description {
  margin-top: 12px;
  padding-right: 12px;
  text-transform: none;
  font-weight: 400 !important;
  font-size: 14px !important;
}
.hero_desc.horizontal .icon_circle {
  margin-top: 0px;
}
.hero_desc.horizontal:hover {
  background: #fafafa;
}
.hero_desc.horizontal:active {
  background: #f5f5f5;
}
</style>
