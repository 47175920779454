<template>
  <div
    class="campaign wfill"
    :class="{ event: campaign.type == 'event' }"
    style="padding: 40px"
  >
    <div style="position: relative; text-align: center">
      <!-- div class="main" :style="{
            background: `#fafafa`,
          }">
      </div -->
      <img
        v-if="$mq == 'mobile'"
        :src="src"
        width="80%"
        height="auto"
        style="margin: auto"
      />
      <img v-else :src="src" width="auto" height="360" style="margin: auto" />
      <!-- div class="main" :style="{
            position: 'absolute',
            top: 0,
            left: 0,
            background: `url(${src}) center center / contain no-repeat`
          }">
      </div -->
    </div>
    <div
      v-if="showImages && campaign.Images && campaign.Images.length > 0"
      class="images flex"
    >
      <img
        v-for="i in [campaign.logo, ...campaign.Images]"
        height="160"
        :class="{ inactive: i.url != src }"
        class="campaign_img"
        :key="i.url"
        :src="i.url"
        @click="src = i.url"
        alt=""
      />
    </div>
    <h6 class="title mt24 spaced flex">
      <span>{{ campaign.title }}</span>
      <button
        v-if="roles && campaign.type == 'livequiz'"
        @click="$router.push(`/quiz/${campaign.id}/rankings`)"
        class="black"
      >
        View Rankings
      </button>
    </h6>
    <button
      v-if="campaign.type == 'campaign' && isActive"
      @click="$bus.redirect(campaign.participationLink)"
      class="participation_button mt16 black"
    >
      Participate Now
    </button>
    <div>
      <a
        v-if="!$bus.isLoggedIn"
        style="padding-left: 6%; padding-right: 6%"
        class="mt12"
        href="#/auth"
      >
        Please log in to participate!
      </a>
      <div v-else>
        <div
          v-if="$bus.profileComplete"
          style="padding-left: 6%; padding-right: 6%"
          class="mt20"
        >
          <button
            v-if="!submission && isActive"
            class="mt12 black x"
            @click="createSubmission"
          >
            Take Quiz
          </button>
          <div class="mt12" v-if="!submission && hasEnded">
            <div class="mt20" style="color: #8d6235">
              This quiz has ended on {{ endDate }}. Looking forward to your
              participation in future quizzes. <br />
            </div>
          </div>
          <div class="mt12" v-if="!submission && !hasBegun">
            <div class="mt20" style="color: #8d6235">
              This quiz will open on {{ startDate }}. Looking forward to your
              participation. <br />
            </div>
          </div>

          <div class="mt12" v-if="submission">
            <div class="mt20" style="color: #8d6235">
              Thank you for your participation in {{ campaign.title }}. <br />
              Quiz attempted on: {{ takenOn }}
            </div>
          </div>
          <!-- <div style="background-color: #f1f1f1;" class="br4 py20 px24">
            <div>
              <h4 class="mb12 fs16">Take the quiz</h4>
              <span v-if="campaign.teamMembers && teamMembers.length < campaign.teamMembers - 1">
                <h5>Add Team Members</h5>
                <p class="mb12 mt8">You are allowed upto {{campaign.teamMembers - 1}} additional team member</p>
                <input v-model="teamMember"
                      placeholder="Email"
                      class="py10"
                      type="email" required>
                <input v-if="campaign.pageName == 'LEDEG'" v-model="teamMemberAge" class="ml4 py10" placeholder="Age" type="number">
                <button @click="addTeamMember" class="black ml8 br0 px12 py8"> Add Team Member</button>
              </span>
              <h5 class="mt12 mb8">Current Team</h5>
              <p>1) {{$bus.user.name}} - {{$bus.user.email}}</p>
              <p v-for="(m, i) in teamMembers"
                :key="m">{{i + 2}}) {{m.name}} - {{m.email}} 
                <i @click="deleteTeamMember(ix)" style="font-size: 12px;" class="ml8 pointer trash icon"></i>
              </p> <br>

              
              <button
                  v-if="submission"
                  class="mt12 black" 
                  @click="updateSubmission">
                Update Team
              </button>
            </div>
          </div> -->
        </div>
        <a
          v-else
          style="padding-left: 6%; padding-right: 6%"
          class="mt12"
          href="#/profile"
        >
          Please complete your profile to take this quiz!
        </a>
      </div>
    </div>
    <p class="info mt24" v-html="campaign.description"></p>
    <div v-if="campaign.Files && campaign.Files.length > 0">
      <h6 class="fs16 mt32 mb12">Attachments</h6>
      <a
        class="files pointer mt4"
        v-for="f in campaign.Files"
        @click="$bus.redirect(f.url)"
        :key="f.name"
        >{{ f.name }}</a
      >
    </div>
  </div>
</template>

<script>
import { Notyf } from "notyf";
import $ from "jquery";
import moment from "moment";
export default {
  name: "campaign",
  props: {
    campaign: { required: true },
    showImages: { default: true },
  },
  data() {
    return {
      src: "",
      submission: null,
      roles: undefined,
      yourAge: "",
      teamMember: "",
      teamMemberAge: "",
      teamMembers: [],
    };
  },
  async mounted() {
    if (this.campaign.eligibleUsers && this.campaign.eligibleUsers.length > 0) {
      let emails = this.campaign.eligibleUsers.map((u) => u.email);
      if (!emails.includes(this.$bus.user.email)) {
        this.$router.push("/ideace");
      }
    }
    this.src = this.campaign.logo.url;
    // this.$req.get(`quiz-entries?quiz.id=${this.campaign.id}&users.id=${this.$bus.user.id}`, {
    //     headers: {
    //       'Authorization': `Bearer ${this.$bus.token}`
    //     }
    //   })
    //   .then(r => {
    //     this.submission = r.data[0]
    //   })
    //   .catch(e => console.log(e))
    this.$req
      .get(
        `getQuizUserSubmission?id=${this.campaign.id}&userId=${this.$bus.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.$bus.token}`,
          },
        }
      )
      .then((r) => {
        this.submission = r.data.entry;
      })
      .catch((e) => console.log(e));
    this.roles = await this.$bus.getLatestRoles();
  },
  computed: {
    isActive: function () {
      var from = this.campaign.validFrom;
      var to = this.campaign.validTo;
      return (
        moment(from).unix() <= moment().unix() &&
        moment(to).unix() >= moment().unix()
      );
    },
    hasBegun: function () {
      var from = this.campaign.validFrom;
      return moment(from).unix() <= moment().unix();
    },
    hasEnded: function () {
      var to = this.campaign.validTo;
      return moment(to).unix() < moment().unix();
    },
    takenOn: function () {
      if (this.submission) {
        return moment(this.submission.created_at).format("hh:mm A Do MMM 'YY");
      }
      return "";
    },
    startDate: function () {
      return moment(this.campaign.validFrom).format("hh:mm A Do MMM 'YY");
    },
    endDate: function () {
      return moment(this.campaign.validTo).format("hh:mm A Do MMM 'YY");
    },
  },
  methods: {
    round: function (n) {
      return Math.round(n);
    },
    simulateClick: function () {
      $(this.$refs.fileInput).click();
    },
    deleteFile: function (id) {
      this.$req
        .delete(`upload/files/${id}`, {
          headers: {
            Authorization: `Bearer ${this.$bus.token}`,
          },
        })
        .then(() => {
          let ix = this.submission.files.findIndex((f) => f.id == id);
          this.submission.files.splice(ix, 1);
        })
        .catch((e) => console.log(e));
    },
    addTeamMember: async function () {
      if (this.teamMember == "") {
        alert("Please fill all inputs");
        return;
      }
      if (this.teamMemberAge == "" && this.campaign.pageName == "LEDEG") {
        alert("Please fill all inputs");
        return;
      }
      let user = await this.$req
        .get(`getUserId?email=${this.teamMember}`)
        .catch((e) => console.log(e));

      if (user == "User does not exist") {
        alert("User does not have an account. Please ask user to sign up.");
      } else if (!this.$bus.checkProfileCompletion(user.data)) {
        alert(
          "User has incomplete profile. Please ask user to complete their profile for participation."
        );
      } else {
        this.teamMembers.push({
          ...user.data,
          age:
            this.campaign.pageName == "LEDEG" ? this.teamMemberAge : undefined,
        });
        this.teamMember = "";
        this.teamMemberAge = "";
      }
    },
    deleteTeamMember: function (ix) {
      this.teamMembers.splice(ix, 1);
    },
    getDateLabel: function (date) {
      return moment(date).format("HH:mm Do MMM YYYY");
    },
    createFormData(file, collection, field, source = "") {
      let data = new FormData();
      data.append("files", file);
      data.append("ref", collection);
      data.append("refId", this.submission.id);
      if (source) {
        data.append("source", source);
      }
      data.append("field", field);
      return data;
    },
    submitFile: function (ev) {
      var MAX_FILE_SIZE = 25 * 1024 * 1024; // 5MB
      let files = ev.target.files[0];
      let cumulative =
        this.submission.files.reduce((s, f) => s + f.size * 1024, 0) +
        files.size;
      if (cumulative > MAX_FILE_SIZE) {
        alert("Submissions cannot exceed 25 Mb");
        return;
      }
      let notyf = new Notyf({ position: { x: "right", y: "top" } });
      notyf.success("Submission upload started");
      let data = this.createFormData(files, "event-submissions", "files");
      this.$baseReq
        .post("upload", data, {
          headers: {
            Authorization: `Bearer ${this.$bus.token}`,
          },
        })
        .then((r) => {
          notyf.success("Submission Received");
          this.submission.files.push(r.data[0]);
        })
        .catch((e) => {
          notyf.success("Upload Failed");
          console.log(e);
        });
    },
    createSubmission: function () {
      this.$router.push(`/quiz/${this.campaign.id}`);
    },
    updateSubmission: function () {
      this.$req
        .put(
          `quiz-entries/${this.submission.id}`,
          {
            users: [this.$bus.user.id, ...this.teamMembers.map((m) => m.id)],
            metadata:
              this.campaign.pageName == "LEDEG"
                ? {
                    ages: [this.yourAge, ...this.teamMembers.map((m) => m.age)],
                  }
                : undefined,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$bus.token}`,
            },
          }
        )
        .then((r) => {
          this.submission = r.data;
        })
        .catch((e) => console.log(e));
    },
  },
};
</script>

<style lang="css" scoped>
.campaign:not(.event) {
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
}
.title,
.info,
.files {
  padding-left: 6%;
  padding-right: 6%;
}
a {
  display: block;
}
.participation_button {
  margin-left: 6%;
}
div.main {
  margin-left: 6%;
  width: 80%;
  height: 360px;
}
@media (min-width: 1400px) {
  div.main {
    height: 500px;
  }
}
@media (max-width: 800px) {
  a {
    font-size: 12px;
  }
}

.images {
  margin-left: 6%;
  margin-top: 24px;
  overflow-x: scroll;
  width: 80%;
}
.images img {
  cursor: pointer;
  margin-right: 12px;
  margin-left: 0px;
}
.images::-webkit-scrollbar {
  display: none;
}
.campaign_img.inactive {
  opacity: 0.4;
}

/* Hide scrollbar for IE, Edge and Firefox */
.images {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.fileUpload {
  background: #eee;
  border-radius: 4px;
  padding: 12px 20px;
}
.addFileButton {
  width: 150px;
  color: #aaa;
  height: 50px;
  background: transparent;
  border: 1px dashed #ccc;
  transition: 0.15s;
}
.addFileButton:hover {
  background: #f4f4f4;
}
.fileView {
  display: flex;
  position: relative;
  min-width: 150px;
  height: 50px;
  border-radius: 4px;
  background: white;
}
.fileName:hover {
  color: #8d6235;
}
.fileDelete {
  color: #ccc;
}
.fileDelete:hover {
  color: tomato;
}
</style>
