<template>
  <div class="pointer relative" style="top: 5px; width: 40px; height: 40px;">
    <div class="absolute vertical flex status-shape fill" style="top: 0px; left 0px;">
      <div
        v-if="status == 'answered'" 
        :style="{borderBottomColor: color}" 
        style="top: 0.5px;" 
        class="relative trapezoid"></div>
      <div class="status-rect"
          :style="{
              height: ['visited', 'answered'].includes(status) ? '30px' : '40px',
              borderRadius: ['review', 'reviewanswered'].includes(status) ? '40px' : ['default', 'active'].includes(status) ? '4px' : 0,
              background: color
            }"></div>
      <div v-if="status == 'visited'" 
          :style="{borderBottomColor: color}" 
          style="top: -0.5px" 
          class="relative trapezoid reverse"></div>
    </div>
    <div v-if="status == 'reviewanswered'"
      class="absolute br8"
      style="background: #7AC021; bottom: 0px; left: 0px; width: 12px; height: 12px;">
    </div>
    <div class="absolute fill flex" style="top: 0px; left: 0px;">
      <div class="mauto fs14" :style="{
        color: status == 'default' ? '#333' : 'white'
      }">{{num}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'quiz-status-block',
  props: {
    status: {default: 'default'},
    num: {default: 1}
  },
  data () {
    return {
      colors: {
        default: '#eee',
        active: '#333',
        answered: '#7AC021',
        visited: '#CB3706',
        review: '#704E91',
        reviewanswered: '#704E91'
      }
    }
  },
  computed: {
    color: function () {
      return this.colors[this.status]
    }
  }
}
</script>

<style>
.status-shape {
  width: 100%;
  height: 100%;
}
.status-rect {
  width: 40px;
  background: #eee;
}
.trapezoid {
	border-bottom: 10px solid #eee;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	height: 0;
	width: 40px;
}
.trapezoid.reverse {
  transform: rotateX(180deg)
}
</style>