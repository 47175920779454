<template>
  <div
    @click.stop="
      showMenu = false;
      showContactMenu = false;
    "
    class="flex nav"
  >
    <img
      class="pointer"
      @click="
        $router.push('/');
        closeMenus();
      "
      src="../assets/ethos_gold_logo.png"
      height="33px"
      style="margin: auto auto auto 0px; z-index: 1; filter: contrast(1.1)"
    />
    <div v-if="$mq != 'mobile'" class="navlinks myauto flex">
      <div
        class="link relative"
        @click.stop="
          // closeMenus();
          showMenu = !showMenu
        "
        :class="{ active: isOffering }"
      >
        <span>Offerings</span>
        <div class="mt20 absolute navbarMenu" v-if="showMenu">
          <hero-section
            v-for="v in $bus.siteData.verticals"
            :key="v.id"
            :vertical="v"
            :horizontal="true"
          />
          <!-- <hero-section
            :vertical="galleryVertical"
            :horizontal="true"
          /> -->
        </div>
      </div>
      <div
        @click="
          $router.push('/about');
          closeMenus();
        "
        class="link"
        :class="{ active: $route.fullPath.includes('about') }"
      >
        <span>About Us</span>
      </div>
      <div
        @click="
          $router.push('/partners');
          closeMenus();
        "
        class="link"
        :class="{ active: $route.fullPath.includes('partners') }"
      >
        <span>Partnerships</span>
      </div>
      <div
        @click="
          $router.push('/gallery');
          closeMenus();
        "
        class="link"
        :class="{ active: $route.fullPath.includes('gallery') }"
      >
        <span>Gallery</span>
      </div>
      <div
        @click.stop="
          // closeMenus();
          showContactMenu = !showContactMenu
        "
        class="link"
        :class="{ active: $route.fullPath.includes('contact') }"
      >
        <span>Contact Us</span>
        <div class="mt20 absolute navbarMenu" v-if="showContactMenu">
          <hero-section
            :horizontal="true"
            v-for="v in contactVerticals"
            :key="v.id"
            :vertical="v"
          ></hero-section>
        </div>
      </div>
    </div>
    <!-- <p class="loggedInAs">Hello {{($bus.user || {username: ''}).username}} </p> -->
    <div
      v-if="$bus.isLoggedIn"
      class="pointer myauto mlauto flex"
      style="z-index: 1"
    >
      <div @click="showDropdown = !showDropdown" class="account_button"></div>
      <div v-if="showDropdown" class="account_dropdown">
        <div
          class="flex account_tab logout"
          @click="
            $router.push('/profile');
            showDropdown = false;
          "
        >
          <v-icon style="width: 12px" class="icon" name="user" />
          <p style="font-size: 12px" class="ml8">Profile</p>
        </div>
        <div
          class="flex account_tab logout"
          @click="
            $bus.logout();
            showDropdown = false;
          "
        >
          <v-icon style="width: 12px" class="icon" name="log-out" />
          <p style="font-size: 12px" class="ml8">Logout</p>
        </div>
      </div>
    </div>
    <button
      v-if="!$bus.isLoggedIn && $mq != 'mobile'"
      @click="$router.push('/auth')"
      style="z-index: 5"
      class="mlauto black"
    >
      Login
    </button>
    <button
      v-if="$mq == 'mobile'"
      class="transparent ml12"
      @click="$emit('toggle-drawer')"
      style="padding: 4px"
    >
      <i style="position: relative; top: 1px" class="material-icons">menu</i>
    </button>
  </div>
</template>

<script>
import heroSection from "./hero-section";
export default {
  name: "navbar",
  components: { heroSection },
  data() {
    return {
      showDashboardButton: false,
      showDropdown: false,
      showMenu: false,
      showContactMenu: false,
      galleryVertical: {
        name: "Gallery",
        description: "Down the memory lane",
        link: "/gallery",
        icon: "Camera",
        color: "#72ACF1",
      },
      contactVerticals: [
        {
          name: "Work With Us",
          description: "Be a part of our growing team",
          link: "/pages/workwithus",
          icon: "briefcase",
          color: "#8D6235",
        },
        {
          name: "Email Us",
          description: "Formal way to reach us",
          link: "mailto:info@ethosempowers.com",
          icon: "mail",
          color: "#72ACF1",
        },
        {
          name: "Whatsapp Us`",
          description: "Fastest way to reach us",
          link: "https://wa.me/message/7KVR3WRNG7P2K1",
          icon: "whatsapp",
          color: "#2EBD44",
        },
      ],
    };
  },
  watch: {
    $route: function () {
      this.showDashboardButton = false;
    },
  },
  computed: {
    isOffering: function () {
      let path = this.$route.fullPath;
      let routes = ["arcause", "connectaid", "ideace", "acedge"];
      return routes.reduce((s, r) => s || path.includes(r), false);
    },
  },
  created() {
    /* eslint-disable */
    this.$bus.$on("redirectToAuth", () => {
      this.$router.push("/auth");
    });
    $(document).on("click", () => {
      this.closeMenus();
    });
  },
  methods: {
    closeMenus: function () {
      this.showMenu = false;
      this.showContactMenu = false;
    },
  },
};
</script>

<style lang="css" scoped>
.nav {
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #eee;
  padding: 20px 40px;
  position: fixed;
  z-index: 20;
  top: 0;
}
@keyframes fadeInDown {
  0% {
    top: 24px;
    opacity: 0;
  }
  100% {
    top: 32px;
    opacity: 1;
  }
}
.navbarMenu {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08), 0 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  width: 400px;
  top: 32px;
  background: white;
  animation: fadeInDown 0.3s;
}
.navlinks {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  left: 0px;
  z-index: 0;
  top: calc(50% - 20px);
  height: 40px;
}
.nav .loggedInAs {
  margin-left: auto;
  color: white;
}
.nav .link {
  margin: auto 20px;
  cursor: pointer;
  transition: 0.15s;
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
  transition: 0.3s;
  color: #334455;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.nav .link label {
  cursor: pointer;
  text-align: center;
  color: #aaa;
}
.nav .link:first-child {
  margin-left: auto;
}
.nav .link:last-child {
  margin-right: auto;
}
.nav .link.active {
  font-weight: bold;
  color: #b98744;
}
.nav .link:hover > span {
  color: #b98744;
}
.nav .link:active > span {
  color: #8d6235;
}

.nav .link span:nth-child(2) {
  font-weight: 700;
}
.nav .link span:nth-child(2) {
  font-size: 9px;
  color: #b98744;
  position: absolute;
  bottom: -16px;
  white-space: nowrap;
  font-weight: normal;
  text-transform: none;
}
.icon {
  width: 20px;
  cursor: pointer;
}
.logout {
  cursor: pointer;
}
.account_button {
  width: 24px;
  height: 24px;
  background: url("https://upload.wikimedia.org/wikipedia/commons/a/ad/Placeholder_no_text.svg")
    center / cover no-repeat;
  border-radius: 100px;
  border: 1px solid #ccc;
}
.account_dropdown {
  z-index: 2000;
  position: absolute;
  background: white;
  right: 32px;
  top: 60px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.12);
}
.account_tab {
  padding: 8px 12px;
  width: 100%;
}
.account_tab:hover {
  background: #eee;
}
</style>
