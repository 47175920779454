var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pointer relative",staticStyle:{"top":"5px","width":"40px","height":"40px"}},[_c('div',{staticClass:"absolute vertical flex status-shape fill",staticStyle:{"top":"0px"}},[(_vm.status == 'answered')?_c('div',{staticClass:"relative trapezoid",staticStyle:{"top":"0.5px"},style:({borderBottomColor: _vm.color})}):_vm._e(),_c('div',{staticClass:"status-rect",style:({
            height: ['visited', 'answered'].includes(_vm.status) ? '30px' : '40px',
            borderRadius: ['review', 'reviewanswered'].includes(_vm.status) ? '40px' : ['default', 'active'].includes(_vm.status) ? '4px' : 0,
            background: _vm.color
          })}),(_vm.status == 'visited')?_c('div',{staticClass:"relative trapezoid reverse",staticStyle:{"top":"-0.5px"},style:({borderBottomColor: _vm.color})}):_vm._e()]),(_vm.status == 'reviewanswered')?_c('div',{staticClass:"absolute br8",staticStyle:{"background":"#7AC021","bottom":"0px","left":"0px","width":"12px","height":"12px"}}):_vm._e(),_c('div',{staticClass:"absolute fill flex",staticStyle:{"top":"0px","left":"0px"}},[_c('div',{staticClass:"mauto fs14",style:({
      color: _vm.status == 'default' ? '#333' : 'white'
    })},[_vm._v(_vm._s(_vm.num))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }