<template>
  <div class="ui right sidebar">
    <p class="link" @click="routeAndClose('/arcause')">Arcause</p>
    <p @click="routeAndClose('/ideace')" class="link">Ideace</p>
    <p @click="routeAndClose('/connectaid')" class="link">Connectaid</p>
    <p class="link" @click="routeAndClose('https://www.acedge.in', true)">Acedge</p>
    <p class="link" @click="routeAndClose('/industreal')">Industreal</p>
    <p @click="routeAndClose('/about')" class="link">About Us</p>
    <p @click="routeAndClose('/partners')" class="link">Partners</p>
    <p v-if="!$bus.isLoggedIn" @click="routeAndClose('/auth')" class="link">
      Sign In
    </p>
  </div>
</template>

<script>
export default {
  name: "sidebar",
  methods: {
    routeAndClose: function(route, isLink=false) {
      if (isLink) {
        this.$bus.redirect(route, false, true)
      }
      this.$router.push(route);
      this.$emit("close");
    }
  }
};
</script>

<style lang="css">
.pushable > .pusher {
  overflow: scroll;
}
.sidebar {
  right: 0px;
  top: 0px;
  width: 220px;
  height: 100%;
  position: fixed;
  background: white;
  z-index: 5000;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.sidebar .link {
  margin: 20px 0px;
  font-size: 20px;
  cursor: pointer;
  transition: 0.15s;
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
  text-align: left;
  padding-left: 40px;
}

.sidebar .link:hover {
  color: #b98744;
}
.sidebar .link:active {
  color: #8d6235;
}
</style>
