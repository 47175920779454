<template>
  <div class="status_parent">
    <h3>{{title}}</h3>
    <div class="pb12 mb8 flex wrap" style="border-bottom: 1px solid #eee;">
      <!-- LEGEND -->
      <div style="width: 50%;" class="flex">
        <quiz-status-block style="transform: scale(0.8); margin: 5px;" status="answered">
        </quiz-status-block>
        <p class="fs12" style="margin-top: auto; margin-bottom: auto;">Answered</p>
      </div>
      <div style="width: 50%;" class="flex">
        <quiz-status-block num="9" style="transform: scale(0.8); margin: 5px;" status="visited">
        </quiz-status-block>
        <p class="fs12" style="margin-top: auto; margin-bottom: auto;">Not Answered</p>
      </div>
      <div style="width: 50%;" class="flex">
        <quiz-status-block num="4" style="transform: scale(0.8); margin: 5px;" status="default">
        </quiz-status-block>
        <p class="fs12" style="margin-top: auto; margin-bottom: auto;">Not Visited</p>
      </div>
      <div style="width: 50%;" class="flex">
        <quiz-status-block num="6" style="transform: scale(0.8); margin: 5px;" status="review">
        </quiz-status-block>
        <p class="fs12" style="margin-top: auto; margin-bottom: auto;">Marked for Review</p>
      </div>
      <div style="width: 100%;" class="flex">
        <quiz-status-block style="transform: scale(0.8); margin: 5px;" status="reviewanswered">
        </quiz-status-block>
        <p class="fs12" style="margin-top: auto; margin-bottom: auto;">Answered & Marked for Review</p>
      </div>
      <!-- -->
    </div>
    <div class="mxauto block_parent">
      <quiz-status-block style="margin: 5px 10px;" v-for="(a, i) in answers" 
        @click.native="$emit('blockClicked', i); current = i"
        :key="a.id" :num="i + 1" :status="statuses[i]">
      </quiz-status-block>
      <!-- div v-for="(a, i) in answers" :key="i" @click="$emit('blockClicked', i); current = i" 
          class="block">
        <div class="content" :class="{
            forReview: forReview.includes(i), 
            visited: visited.includes(i), 
            answered: a != '', 
            current: current == i
          }">
          <span class="n">{{i + 1}}</span>
        </div>
      </div -->
      <div class="block" @click="$emit('blockClicked', answers.length); current = answers.length">
        <div class="content" :class="{current: current == answers.length}">
          <span class="n">E</span>
        </div>
      </div>
    </div>
    <div class="flex meta">
      <button @click="$emit('saveNExit')" 
        class="fs16 relative black mauto nowrap">Submit & Exit Quiz</button>
    </div>
  </div>
</template>

<script>
import quizStatusBlock from './quiz-status-block'
export default {
  name: 'quiz-status',
  components: {quizStatusBlock},
  props: {
    title: {default: 'Quiz'},
    answers: {default: () => ['A', '', 'B', 'D', '', 'F']},
    visited: {default: () => []},
    forReview: {default: () => []}
  },
  data () {
    return {
      current: 0
    }
  },
  computed: {
    statuses: function () {
      return this.answers.reduce((sum, ans, i) => {
        
        if (this.visited.includes(i)) {
          if (ans == '') {
            if (this.forReview.includes(i)) {
              var status = 'review'
            } else {
              status = 'visited'
            }
          } else {
            if (this.forReview.includes(i)) {
              status = 'reviewanswered'
            } else {
              status = 'answered'
            }
          }
        }
        // status = this.forReview.includes(i) ? 'review' : 'default'
        // if (status == 'review' && ans != '') {
        //   status = 'reviewanswered'
        // }
        // sum[i] = (sum[i] || '')
        if (this.current == i) {
          status = 'active'
        }
        sum[i] = status
        return sum
      }, {})
    }
  },
  methods: {
    setCurrent (n) {
      this.current = n
    }
  }
}
</script>

<style lang="css" scoped>
  .status_parent {
    width: 300px;
    padding: 0px 16px 60px 0px;
    position: relative;
    margin-right: 40px;
    height: calc(100vh - 200px);
    overflow-y: scroll;
    flex-shrink: 0
  }
  .status_parent > h3 {
    text-align: center;
    margin: 30px 0px 28px 0px;
    color: #585757;
  }
  .block_parent {
    display: flex;
    flex-wrap: wrap;
    width: 280px;

  }
  .block {
    display: flex;
    width: 25%;
    height: 50px;
    cursor: pointer;
  }
  .block .content {
    display: flex;
    width: 40px;
    height: 40px;
    margin: auto;
    background: #eee;
    border-radius: 4px;
  }
  .content .n {
    margin: auto;
    font-size: 16px;
  }
  .content.answered {
    background: #B98744;
  }
  .content:not(.answered) {
    background: #E07074,
  }
  .content.answered .n, .content.current .n {
    color: white;
  }
  .meta {
    bottom: 0px;
    width: 100%;
    padding: 12px 4px;
  }
  .meta button {
    width: 100%;
  }

  .block .content.visited {
    background: #E07074
  }
  .block .content.forReview {
    background: #689FBE;
  }
  .content.answered {
    background: #B98744 !important;
  }
  .content.current {
    background: black !important;
  }
</style>